import React, { useEffect, useRef, useState } from "react";
import "./datePicker.module.css";
import ReactDatePicker from "react-datepicker";
import { format, parse } from "date-fns";
import { Button } from "dms-lib";
import moment from "moment/moment";
import createDateFromString from "../../../../helpers/createDateFromString";
import TimePickerSlider from "../timePickerSlider/TimePickerSlider";
import { getDocument, isValidDate } from "../../../../util/Util";

interface DatePickerProps {
  name: string;
  includeTime?: boolean;
  currentDate?: boolean;
  dateFormat?: string;
  initialDate?: Date;
  onChangeCallBack?: (selectedDate: Date) => void;
}

const DatePicker = (props: DatePickerProps): JSX.Element => {
  const {
    includeTime = false,
    currentDate = false,
    dateFormat = "yyyy/MM/dd",
    name,
    initialDate,
    onChangeCallBack,
  } = props;
  const timeFormat = "HH:mm";

  const finalDateFormat = includeTime
    ? `${dateFormat} ${timeFormat}`
    : dateFormat;

  const getInitialDate = () => {
    if (currentDate && !initialDate) {
      return createDateFromString(format(new Date(), finalDateFormat));
    }

    if (isValidDate(initialDate, finalDateFormat)) {
      return createDateFromString(format(initialDate, finalDateFormat));
    }
    return null;
  };

  const [selectedDate, setSelectedDate] = useState(getInitialDate());

  const [datePickerIsOpen, setDatePickerIsOpen] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  const datePickerRef = useRef<HTMLDivElement>();
  const isManualInputRef = useRef(false);

  const setDateValidState = (isValid) => {
    const timerElement: HTMLInputElement = getDocument(document).querySelector(
      `input[name='${props.name}']`
    );
    timerElement.setCustomValidity(isValid ? "" : "invalid date");
  };

  const onChange = (val) => {
    if (!isManualInputRef.current && isValidDate(val, finalDateFormat)) {
      setSelectedDate(val);
      setDateValidState(true);
      if (onChangeCallBack) {
        onChangeCallBack(val);
      }
    }
    isManualInputRef.current = false;
  };

  const handleChangeRaw = (value) => {
    isManualInputRef.current = !!value;

    if (!value) {
      setDateValidState(false);
      onChangeCallBack(null);
      return;
    }

    if (isValidDate(value, finalDateFormat)) {
      const newDate = new Date(value);
      setSelectedDate(newDate);
      setDateValidState(true);
      if (onChangeCallBack) {
        onChangeCallBack(newDate);
      }
    } else {
      setDateValidState(false); // not a valid date
      if (onChangeCallBack) {
        onChangeCallBack(null);
      }
    }
  };

  useEffect(() => {
    if (isValidDate(initialDate, finalDateFormat)) {
      setSelectedDate(new Date(initialDate));
    }
  }, [initialDate]);

  const validateActiveComponent = () => {
    const activeComponent = getDocument(document).querySelector(
      "[class*=-active-component]"
    );
    if (!activeComponent) {
      setActiveElement(
        `${props.name.trim().replace(" ", "")}-active-component`
      );
    }
  };

  const hideDatePicker = (): void => {
    setDatePickerIsOpen(false);
    setActiveElement(null);
  };

  const handleClickOutside = (event: MouseEvent): void => {
    const targetElement = event.target as Element;
    if (
      !datePickerRef.current?.contains(targetElement) ||
      targetElement.querySelector("svg.icon-arrow-down")
    ) {
      const dateTimeElement = getDocument(document).querySelector(
        `input[name='${props.name}']`
      );
      if (
        !moment(
          dateTimeElement.getAttribute("value"),
          `${dateFormat.toUpperCase()} ${timeFormat}`,
          true
        ).isValid()
      ) {
        dateTimeElement.setAttribute(
          "value",
          format(selectedDate, finalDateFormat)
        );
      }
      if (onChangeCallBack) {
        onChangeCallBack(new Date(dateTimeElement.getAttribute("value")));
      }
      setDateValidState(true);
      hideDatePicker();
      getDocument(document).removeEventListener("click", handleClickOutside);
    }
  };

  const showDatePicker = (): void => {
    setDatePickerIsOpen(true);
    validateActiveComponent();
    getDocument(document).addEventListener("click", handleClickOutside);
  };

  const handleCallback = (sliderData) => {
    const timerElement = getDocument(document).querySelector(
      `input[name='${props.name}']`
    );
    const dateTime = timerElement.getAttribute("value");
    const date = dateTime.split(" ")[0];
    if (date === "") {
      return;
    }
    const newDate = parse(`${date} ${sliderData}`, finalDateFormat, new Date());
    setSelectedDate(newDate);
    setDateValidState(true);
    if (onChangeCallBack) {
      onChangeCallBack(newDate);
    }
    timerElement.setAttribute("value", format(newDate, finalDateFormat));
  };

  useEffect(() => {
    let monthDatePicker: HTMLDivElement;

    if (datePickerIsOpen) {
      const thisDocument = getDocument(document);
      const element = thisDocument.querySelectorAll(".react-datepicker__day");
      Array.from(element).forEach((el) => {
        el.classList.remove("react-datepicker__day--keyboard-selected");
      });
      monthDatePicker = thisDocument.querySelector(".react-datepicker__month");
      monthDatePicker.addEventListener("dblclick", hideDatePicker);
    }

    return () => {
      if (monthDatePicker) {
        monthDatePicker.removeEventListener("dblclick", hideDatePicker);
      }
    };
  }, [datePickerIsOpen]);

  return (
    <div
      className={
        activeElement != null
          ? `${"date-picker "}${activeElement}`
          : "date-picker"
      }
      data-cy={name}
      data-cr={name}
      ref={datePickerRef}
    >
      <ReactDatePicker
        name={name}
        selected={selectedDate}
        onChange={onChange}
        onBlur={hideDatePicker}
        dateFormat={finalDateFormat}
        showTimeInput
        onChangeRaw={(event) => handleChangeRaw(event.target.value)}
        open={datePickerIsOpen}
        placeholderText={finalDateFormat}
        popperPlacement="bottom-end"
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      >
        {includeTime && (
          <TimePickerSlider
            initialHour={selectedDate.getHours()}
            initialMinute={selectedDate.getMinutes()}
            datePickerCallBack={handleCallback}
          />
        )}
        <div className="react-datepicker-confirmation">
          <Button
            color="primary"
            variant="text"
            className="react-datepicker-button"
            onClick={hideDatePicker}
          >
            Done
          </Button>
        </div>
      </ReactDatePicker>

      <button
        className="date-picker-icon"
        data-cy={`${name}-icon`}
        type="button"
        onClick={() => (datePickerIsOpen ? hideDatePicker() : showDatePicker())}
      >
        {!datePickerIsOpen ? (
          <span className="icon-arrow-down" />
        ) : (
          <span className="icon-arrow-up" />
        )}
      </button>
    </div>
  );
};

export default DatePicker;
