import React, { useCallback, useEffect, useState } from "react";
import "./ogConfigurator.module.css";
import { useSelector } from "react-redux";
import { ReactFlowProvider } from "reactflow";
import { AlertColor } from "@mui/material";
import OgTemplatePanel from "./ogTemplatePanel/OgTemplatePanel";
import OgTaskTypesPanel from "./ogTaskTypesPanel/OgTaskTypesPanel";
import StateService from "../../services/StateService";
import { useStateContext } from "../../contexts/StateContext";
import { OgTemplateDto } from "../../models/ogTemplate/OgTemplateDto";
import { useTabActionsContext } from "../../contexts/TabActionsContext";
import OgEditPanel from "./ogEditPanel/OgEditPanel";
import { readOnlyMode } from "../../store/root";

const OgConfigurator = (): JSX.Element => {
  const stateService: StateService = useStateContext();
  const isReadOnly = useSelector(readOnlyMode);
  const { setActivateAll, setEditActionsAllowed, setConfigMainButtons } =
    useTabActionsContext();
  const { alertSubject } = stateService;
  const [loadTemplates, setLoadTemplates] = useState<boolean>(false);
  const [selectedOgTemplate, setSelectedOgTemplate] =
    useState<OgTemplateDto>(null);

  const notificationCallback = useCallback(
    (msg: string, severity: AlertColor = "error") => {
      if (msg) {
        alertSubject.next({ title: msg, severity });
      }
    },
    []
  );

  useEffect(() => {
    setConfigMainButtons({
      showCreateButton: true,
      showEditButton: !!selectedOgTemplate,
      showDeleteButton: !!selectedOgTemplate,
    });
    setActivateAll(false);
    setEditActionsAllowed(!!selectedOgTemplate);
  }, [selectedOgTemplate]);

  return (
    <div data-cr="og-configurator" className="og-configurator-main">
      <div className="og-configurator-left-panel">
        <OgTemplatePanel
          selectedOgTemplate={selectedOgTemplate}
          setSelectedOgTemplate={setSelectedOgTemplate}
          loadTemplates={loadTemplates}
          setLoadTemplates={setLoadTemplates}
          notificationCallback={notificationCallback}
        />
      </div>

      <div className="og-configurator-middle-panel">
        <ReactFlowProvider>
          <OgEditPanel
            selectedOgTemplate={selectedOgTemplate}
            loadTemplates={loadTemplates}
          />
        </ReactFlowProvider>
      </div>
      {!isReadOnly && (
        <div className="og-configurator-right-panel">
          <OgTaskTypesPanel errorCallback={notificationCallback} />
        </div>
      )}
    </div>
  );
};

export default OgConfigurator;
